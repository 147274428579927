<template>
    <b-overlay :show="isLoadingData" style="padding: 1rem;">
        <b-card>
            <div v-if="bookingEdit">
                <b-row>
                    <b-col>
                        <b-card class="border rounded">
                            <div id="section-to-print">
                                <b-row>
                                    <b-col md="8" class="mb-2 text-dark">
                                        <span class="subtitle">{{ $t('reservation number') }} </span>
                                        <span class="title mr-2">{{ bookingFullInfo.invoice }}</span>
                                        <span class="subtitle mr-2"> {{ $t('created') }}: {{ formDate(bookingFullInfo.createdate) }} </span>
                                        <span class="subtitle text-capitalize"> {{ $t('booking') }}: {{ $t('is') }} {{ bookingFullInfo.occupanttype == 1 ? this.$t('owner') : bookingFullInfo.occupanttype == 2 ? this.$t('Invited') : '-' }}</span>
                                    </b-col>
                                    <b-col md="">
                                        <figure class="head-logo float-right">
                                            <img :src="require('@/assets/images/logo/Logo_TFC_Negro.png')" alt="logo" class="head__img">
                                        </figure>
                                        <b-button
                                            v-b-tooltip.hover
                                            :title="$t('My Reservations')"
                                            :to="{name: 'my-reservations'}"
                                            variant="primary"
                                            class="float-right"
                                        > <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" /> <!-- {{ $t('My Reservations') }} -->
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="2">
                                        <span class="font-weight-bold">
                                            {{ bookingFullInfo.guestname }}
                                        </span><br>
                                        <span class="font-weight-bold">
                                            {{ mainGuest.email }}
                                        </span> <br>
                                        <span class="font-weight-bold">
                                            {{ mainGuest.phone }}
                                        </span>
                                    </b-col>
                                    <b-col md="4">
                                        <span class="font-weight-bold text-secondary">
                                            {{ bookingFullInfo.resortname }}
                                        </span><br>
                                        <span class="font-weight-bold text-secondary">
                                            {{ bookingFullInfo.roomname }}
                                        </span> <br>
                                        <span class="font-weight-bold text-secondary text-capitalize">
                                            {{$t('Status')}}:
                                            <span :class="`even-more-bolder text-${badgeStatus.variant}`">
                                                <b>{{$t(badgeStatus.statusName)}}</b>
                                            </span>
                                        </span>
                                    </b-col>
                                    <b-col align-self="end">
                                        <b-button variant="primary" size="sm" @click="showCoupon" class="mt-1 float-right">
                                            <feather-icon icon="PrinterIcon" size="14" class="align-middle m-0" style="color: white" /> {{$t('Download')}}
                                        </b-button>
                                        <b-button variant="primary" size="sm" @click="enableEdition" class="mt-1 float-right mr-1" :disabled="isEditing" v-if="canEditBooking">
                                            <feather-icon icon="Edit3Icon" size="14" class="align-middle m-0" style="color: white" /> {{$t('Edit')}}
                                        </b-button>
                                        <!-- <b-button
                                            v-if="canEditBooking && areBenefitsToAuthorize"
                                            variant="primary"
                                            size="sm"
                                            :disabled="isCancelingRsv"
                                            class="mt-1 mr-2 float-right"
                                            @click="authorizeBenefits"
                                        >                                                                                        
                                            {{ $t("Authorize benefits") }}
                                        </b-button> -->
                                        <b-button variant="danger" size="sm" :disabled="isCancelingRsv" class="mt-1 mr-2 float-right" @click="requestCancelBooking" v-if="canEditBooking">
                                            <font-awesome-icon icon="fa-solid fas fa-trash" />
                                            {{ $t("cancel rsv") }}
                                        </b-button>
                                        
                                    </b-col>
                                </b-row>
                                <hr class="border-dark">

                                <div v-if="!canEditBooking">
                                    <b-alert variant="danger" show  class="mt-1">
                                        <div class="alert-body text-justify">
                                            <p class="font-weight-bold text-danger text-center">{{ msgCanEditBooking }}</p>
                                        </div>
                                    </b-alert>
                                </div>

                                <ReservationForm
                                    class="mt-1"
                                    ref="reservationForm"
                                    :bookingEdit="bookingEdit"
                                    :isSearchingRooms="isSearchingRooms"
                                    :isSavingRequestReservation="isSavingRequestReservation"
                                    :isEditing="isEditing"
                                    :isRequest="false"			                        
                                    :canEditBooking="canEditBooking"
                                    @send-request-booking="handlerSendRequest"                                    
                                    @request-cancel-booking="requestCancelBooking"
                                    @enable-edition="enableEdition"    
                                    @authorize-benefits-booking="authorizeBenefits"                                
                                    @not-authorize-benefits-booking="notAuthorizeBenefits"                                

                                />                               
                                <ModalCancelReservation :detail="bookingFullInfo" :isLoading="isCancelingRsv" :cancelRsv="cancelRsv" :requiresTerms="requiresTermsAndConditions" @cancel-rsv-owner="cancelBooking" />
                            </div>
                        </b-card>
                    </b-col>
                </b-row>

                <!-- <b-row>
                    <b-col>
                        <h6>bookingFullInfo</h6>
                        <pre>{{ bookingFullInfo }}</pre>
                    </b-col>
                    <b-col>
                        <h6>bookingEdit</h6>
                        <pre>{{ bookingEdit }}</pre>
                    </b-col>
                </b-row> -->
            </div>
            
            <div class="not-found-btn" v-if="bookingNotFound">
                <b-button
                    :to="{name: 'my-reservations'}"
                    variant="warning"
                    class="btn-back mb-2"
                > <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" /> {{ $t('My Reservations') }}
                </b-button>
            </div>

            <b-alert variant="danger" show class="mb-0 alert-f" v-if="bookingNotFound">
                <div class="alert-body text-center"><feather-icon icon="InfoIcon" class="mr-50" />{{$t('Booking information not found')}}</div>
            </b-alert>

        </b-card>
    </b-overlay>
</template>

<script>
import { mapActions, mapState , mapMutations } from 'vuex'
import SkeletonMyReservations from '@/modules/owners/components/reservations/skeletons/SkeletonMyReservations'
import ReservationForm from '@/modules/owners/components/reservations/ReservationForm'
import ModalCancelReservation from '@/modules/owners/components/reservations/ModalCancelReservation'
import Rooms from '@/modules/owners/components/reservations/Rooms'
import { heightFade } from "@core/directives/animations";
import { market } from "@/modules/market/mixins/market";
import { utils } from "@/modules/owners/mixins/utils"
import * as moment from 'moment'
import { bookingBadgeStatus } from '@/data/data'

import { toJson, showAlertMessage,formatDate } from "@/helpers/helpers"
import { createPayloadRequestBooking, formatBookingToEdit, createPayloadBooking, formatRooms, createPayloadModifyBooking } from "@/helpers/bookingHelper"

export default {
    mixins: [market, utils ],
    components: {
        ReservationForm,
        ModalCancelReservation,
        SkeletonMyReservations,
        Rooms
    },
    directives: {
        heightFade,
    },
    async created() {
        await this.init()
    },
    destroyed(){
        this.setYearsToUseInBenefits([])
		this.setBenefitsToUseInBooking([])
    },
    data() {
        return {
            isLoadingData: false,
            attrLink: process.env.VUE_APP_IMG_SRC_API,
            isEditing: false,
            roomsInfo: null,
            isUpdatingReservation: false,
            showRooms: false,
            bookingFullInfo:null,
            bookingEdit: null,
            originalBookingInfo: null,
            bookingToSend: null,
            isCancelingRsv: false,
            cancelRsv: null,
            requiresTermsAndConditions: false,
            isSearchingRooms: false,
            isSavingRequestReservation:false,
            canEditBooking: false,
            msgCanEditBooking: '',
            bookingNotFound: false,
			benefits: [],
            benefitsBooking: [],           
        }
    },
    computed: {
        ...mapState("auth", ["user", "myContracts", 'userAdmin']),
		...mapState('owners', ['cancellationReasons', 'benefitsToUseInBooking','yearsBenefitsList']),

        mainGuest(){
            return this.bookingFullInfo.namesbeneficiaries.find(guest => guest.ismain) || this.bookingFullInfo.namesbeneficiaries[0]
        },
        badgeStatus(){
            const { status } = this.bookingFullInfo
            return bookingBadgeStatus.find(state => state.status == status)
        },
        areBenefitsToAuthorize(){
            const result = this.benefitsToUseInBooking.some(item => item.id !== null && item.authorizedBy === null)
            return result
        }      
    },
    methods: {
        ...mapActions('owners', ['fetchBookings', 'getRooms', 'validateBooking', 'pushActionsService', 'updateBooking', 'bookingModifiedOwner', 'cancelOwnerBooking', 'fetchBookingsPenalities', 'fetchCancellationReasons','fetchBenefitsBookingOwner', 'updateBenefitsBookingOwner','fetchBenefitsByYearAndContract']),
        ...mapMutations('owners', ['setBookings', 'setYearsToUseInBenefits','setBenefitsToUseInBooking']),

        async init(){
            this.isLoadingData = true            
			this.setYearsToUseInBenefits([])
			this.setBenefitsToUseInBooking([])                
            const idBooking = this.$route.params.id
            let bookingHasBenefits = false 
            if( this.isANumber(idBooking) ){
				if (!this.cancellationReasons || this.cancellationReasons?.length == 0) await this.fetchCancellationReasons(70)
                const booking = await this.fetchBookings({id: idBooking})
                this.bookingNotFound = !booking.length
                let years = []
                if( booking.length ){
                    this.bookingFullInfo = booking[0]
                    // console.log(booking[0])
                    this.bookingEdit = formatBookingToEdit(booking[0])
                    this.originalBookingInfo = formatBookingToEdit(booking[0])
                    bookingHasBenefits = booking[0].hasbenefits                    
                    this.canEditBooking = this.canEditReservation(this.bookingEdit)
                    years = this.getYearsBooking(booking[0].datein, booking[0].dateout)
				    this.setYearsToUseInBenefits( years )                
                }
                if( bookingHasBenefits ){
                    const response = await this.fetchBenefitsBookingOwner({ownerbookingid: idBooking })            
                    const benfitsYear = await this.getBenefitsByContractYear(years[0]) 
                    const benfitsYear2 = years[1] ? await this.getBenefitsByContractYear(years[1]) : []
                    const benefitsBooking = this.generateBenefitsBooking([...benfitsYear, ...benfitsYear2], response.data )  
                    this.setBenefitsToUseInBooking( benefitsBooking )
                }
                const termsConditions = await this.getParameters(8)
                this.requiresTermsAndConditions = Boolean(termsConditions?.value)
                // status 3, es cancelada
            }            
            this.isLoadingData = false
        },
        isANumber(string){
            return string ? !isNaN( string ) && string > 0 : false
        },
        enableEdition(){
            this.isEditing = !this.isEditing
        },
        async getBenefitsByContractYear(year){
            const { id } = this.myContracts.contractSelected 
            const rawBenefits = await this.fetchBenefitsByYearAndContract({idContract: id, year })
            const benefits = this.formatBenefits(rawBenefits, year)  // && this.booking.isAGuest					            	
            return benefits	
		},    
        formatBenefits( rawBenefits, year){
			let benefits = []
			rawBenefits.forEach( beneficio => {
				const { benefit } = beneficio
				benefit.qty = 0
				benefit.year = year
				benefit.transaction = benefit.idTransa
				if( benefit.useInBookingOwners ){
					benefits.push( benefit )
					delete benefit.idTransa
					delete benefit.product
					delete benefit.benefitqty

				}
			})			
			return benefits
		},	
        async authorizeBenefits(){
			this.isSavingRequestReservation = true

            if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}
            const idBooking = this.$route.params.id

            const benefits = this.benefitsToUseInBooking.map(item => {
                return {	
                    id: item.id || null,
                    balance: item.balance,
                    benefit: item.benefit,                        
                    createdBy: item.createdBy || 116,
                    owner: item.owner || this.user.idOwner,
                    authorizedBy: item.authorizedBy || this.user.idOwner,                
                    status: '2',                
                    ownerbooking: idBooking,
                    transaction: item.transaction,
                    qty: item.qty,					
                }
            }).filter(item => item.qty > 0 || item.id !== null)

            if( !benefits.length ){
				this.isSavingRequestReservation = false		
                return
            }

			const isConfirmed = await this.confirmUseBenefits()

            if( !isConfirmed ){  
				this.isSavingRequestReservation = false		
				return
			}
            
            const response = await this.updateBenefitsBookingOwner( {idBooking, benefits} ) 
            if( response.status ){
                const title = this.$t("authorized benefits")
                const text = this.$t("Benefits have been authorized in the reservation")
                this.$swal.fire({ icon: "success", title, text }) 
            }          
			this.isSavingRequestReservation = false	            
            await this.init()
        },

        async notAuthorizeBenefits(){           
			this.isSavingRequestReservation = true

            if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}
            const idBooking = this.$route.params.id

            const benefits = this.benefitsToUseInBooking.map(item => {
                return {	
                    id: item.id || null,
                    balance: item.balance,
                    benefit: item.benefit,                        
                    createdBy: item.createdBy || 116,
                    owner: item.owner || this.user.idOwner,
                    authorizedBy: null,                
                    status: 3,                
                    ownerbooking: idBooking,
                    transaction: item.transaction,
                    qty: item.qty,					
                }
            }).filter(item => item.qty > 0 || item.id !== null)

            if( !benefits.length ){
				this.isSavingRequestReservation = false		
                return
            }

			const isConfirmed = await this.confirmUseBenefits( this.$t('Are you sure you do not want to authorize the following benefits') )

            if( !isConfirmed ){  
				this.isSavingRequestReservation = false		
				return
			}
            
            const response = await this.updateBenefitsBookingOwner( {idBooking, benefits} ) 
            if( response.status ){
                const title = this.$t("benefits not authorized")
                const text = this.$t("Benefits in reserve were not authorized")
                this.$swal.fire({ icon: "success", title, text }) 
            }          
			this.isSavingRequestReservation = false	            
            await this.init()
        },
        generateBenefitsBooking( benefitsYear, benefitsBooking ){
            let benefits = []
            benefitsYear.forEach( benefitYear => {
                const foundItem = benefitsBooking.find( benefitBooking =>  benefitBooking.transaction === benefitYear.transaction)                
                if (foundItem) {
                    benefitYear.id = foundItem.id
                    benefitYear.qty = foundItem.qty
                    benefitYear.authorizedBy = foundItem.authorizedBy
                    benefitYear.status = foundItem.status
                    benefitYear.createdBy = foundItem.createdBy
                    benefitYear.owner = foundItem.owner
                    benefitYear.ownerbooking = foundItem.ownerbooking
                    benefits.push( benefitYear )
                }            
            })
            return benefitsYear
		},       
        getYearsBooking(dateInit, dateEnd){
			const startYear = new Date(dateInit).getUTCFullYear()
			const endYear = new Date(dateEnd).getUTCFullYear()
			return startYear !== endYear ? [startYear, endYear] : [startYear]
		},     
        async handlerSendRequest(booking) {
            this.isSearchingRooms = true
            this.showRooms = false

            await this.pushData('onClick', 'requestUpdateBooking', 'requestUpdateBooking', this.$route.name, `idBooking: ${booking.idBooking}`) // push a back del registro

            //validar primero disponibillidad de noches
            const payloadRequest = toJson(createPayloadRequestBooking(booking, this.myContracts?.contractSelected, this.owner))
            const rawRooms = await this.getRooms(payloadRequest)

            if( rawRooms.length === 0 ){
                this.noNightsAvailable = true
                this.isSearchingRooms = false
                // push a back del registro
                await this.pushData('onClick', 'requestUpdateBookingFailed', 'requestUpdateBookingFailed', this.$route.name, `It looks like you have no nights available | idBooking: ${booking.idBooking}`)
                return showAlertMessage( this.$t("Not availability") , "InfoIcon", this.$t("It looks like you have no nights available"), "danger", 5000, "bottom-right")
            }

            if (rawRooms.length > 0 ) this.roomsInfo = formatRooms(rawRooms)
            //validar disponibilidad para proceder rsv
            const { isInvalidNights, needAuthorization, existAReservation, hasReservationsNearDates, invalidResponse } = await this.validateRsv(booking)
            // console.log({ isInvalidNights, needAuthorization, existAReservation, hasReservationsNearDates })

            if ( invalidResponse || isInvalidNights || existAReservation || needAuthorization) {
                let icon = "error"
                this.isSearchingRooms = false

                const title =
                invalidResponse ? `${this.$t("something failed")}` :
                isInvalidNights ? `${this.$t("Not enough nights")}` :
                existAReservation ? `${this.$t("A reservation found")}` :
                needAuthorization ? this.$t("This reservation does not comply with the contract criteria") : ''

                const text =
                invalidResponse ? `${this.$t("try again")}` :
                isInvalidNights ? `${this.$t("The reservation does not have enough nights")}` :
                existAReservation ? `${this.$t("There is already a reservation within these days")}` :
                needAuthorization ? this.$t("This reservation does not comply with the contract criteria...") : ''

                //si el contracto es CLASSIC y la respuesta de getOwnerBookingBlack trae un additional en true
                if (existAReservation) icon = "warning"
                // push a back del registro
                await this.pushData('onClick', 'requestUpdateBookingFailed', 'requestUpdateBookingFailed', this.$route.name, `${title} | idBooking: ${booking.idBooking}`)
                return this.$swal.fire({ icon, title, text })
            }

            // Si hay reservaciones Cerca
            if( hasReservationsNearDates ){
                this.isSearchingRooms = false
                const manyBooks = hasReservationsNearDates?.data?.length > 1
                await this.nearDatesModal({
                    icon: 'AlertCircleIcon',
                    title: manyBooks ? this.$t("Several nearby reserves have been found") : this.$t("A nearby reserve found"),
                    message: `${ manyBooks ? this.$t("There are reservations") : this.$t("There is a reserve")} ${ this.$t("near the selected days") } ${ this.$t("Choose other dates with a difference of")}   ${hasReservationsNearDates?.lapse} ${ this.$t("days") }`,
                    data: hasReservationsNearDates?.data
                })
                // push a back del registro
                await this.pushData('onClick', 'requestUpdateBookingFailed', 'requestUpdateBookingFailed', this.$route.name, `A nearby reserve found near the selected days | idBooking: ${booking.idBooking}`)

                return false
            }

            this.bookingToSend = booking
            // si del listado de rooms uno tiene el idconfigurationRoom == booking.idroomsConfiguration, actualizo directamente
            const selectedRoom = this.roomsInfo.find(item => item.idconfigurationRoom == booking.idroomsConfiguration)
            if (selectedRoom) await this.bookRoom(selectedRoom)
            else this.showRooms = true //sino muestro la lista de rooms

            this.isSearchingRooms = false
        },
        canEditReservation(booking){
            const { reservetype } = booking // 1-> principal, 2-> adicional

            // para validar si se puede editar por status
            const statusValid = [ 1, 2, 5] // Pendiente Confirmación, Confirmada, Pendiente Modificación
            const { status } = booking
            const numberStatus = parseInt(status)
            // si el status del booking está en el arreglo de status válidos
            const canEditBookingByStatus = statusValid.includes(numberStatus)

            // para validar por fecha de checkin
            const dateCheckin = moment(booking.checkIn)
            const currentDate = moment().startOf('day')
            const daysDiff = dateCheckin.diff(currentDate, 'days')
            // los días de diferencia entre el checkin y la fecha actual sean mayores a cero
            const canEditBookingByCheckin =  daysDiff > 0

            if( !canEditBookingByStatus ){
                this.msgCanEditBooking += ' ' + this.$t("Reservation cancelled, cannot be modified")
            }

            if( !canEditBookingByCheckin ){
                this.msgCanEditBooking += ' ' + this.$t("You cannot modify a reservation with the following check-in date") + ` ${booking.checkIn}`
            }
            // si el status del booking está en el arreglo de status válidos, La fecha para edición es válida
            return canEditBookingByStatus && canEditBookingByCheckin  && reservetype !== '2'

        },
        async validateRsv(booking) {
            const { checkIn, checkOut, idBooking } = booking;
            const { id, membershipcode } = this.myContracts.contractSelected; //id del contrato
            const payload = {
                idOwner: this.user.idOwner,
                idBooking,
                idContract: id,
                dateIn: checkIn,
                dateOut: checkOut,
                isEdit: true,
            }
            const response = await this.validateBooking(payload); //petición al endpoint getOwnerBookingBlack

            if (response){
                const { status, cutoff, additional, info, booksNear  } = response
                return {
                    isInvalidNights: false,
                    needAuthorization: status || cutoff,
                    existAReservation: membershipcode === 'CLASSIC' && additional,
                    reasonAuthorization : info ? `${info?.name || '' }, días: ${info?.total || '' } `: '',
                    hasReservationsNearDates: booksNear,
                    invalidResponse: false
                }
            } else return {invalidResponse: true}
        },
        
        async bookRoom( room ){
            const idBooking = this.$route.params.id

            this.isSavingRequestReservation = true

            const { nightsContract, idconfigurationRoom } = room
            const payload = createPayloadBooking(this.bookingToSend, this.myContracts?.contractSelected)

            payload.isEdit = this.bookingToSend.originalCheckInCheckOut != this.bookingToSend.checkInCheckOut
            payload.nightsContract = nightsContract
            payload.nightsXtra = false
            payload.idconfigurationRoom = idconfigurationRoom
            payload.idBooking = idBooking ? parseInt(idBooking): null // si es edición este vendra con valor

            const response = await this.updateBooking(payload)
            if (response) {
                const { status, message } = response
                if(status){
                    const ownerPayload = createPayloadModifyBooking(this.bookingToSend, room, this.myContracts?.contractSelected)
                    const valid = await this.bookingModifiedOwner(ownerPayload)

                    if (valid) {
                        
                        this.isSavingRequestReservation = false
                        await this.requestBookingWasSuccessfully()
                        // push a back del registro
                        await this.pushData('onSubmit', 'requestUpdateBookingSuccessfull', 'requestUpdateBookingSuccessfull', this.$route.name, `idBooking: ${idBooking}`)
                    } else this.requestBookingError(`${this.$t('something failed')}. ${this.$t('try again')}`)
                } else this.requestBookingError(message)
            } else this.requestBookingError(`${this.$t('something failed')}. ${this.$t('try again')}`)

        },
        async requestBookingWasSuccessfully(){
            const { housingid, id } = this.myContracts.contractSelected // hFousingid del contrado seleccionado

            const title = this.$t("Your reservation has been confirmed")
            const text = this.$t("Your reservation has been confirmed the E-Concierges")
            this.$swal.fire({ icon: "success", title, text }) //muestro alerta de exito            
			this.setYearsToUseInBenefits([])
			this.setBenefitsToUseInBooking([])
            //pedir y mutar el listado de reservas
            this.$refs.reservationForm.resetForm()
            const bookings = await this.fetchBookings({housingid, idContract: id})
            this.setBookings(bookings)
            this.$router.push({ name: 'my-reservations',}) // lo mando a la vista de reservas
        },
        async showCoupon(){
            const order = `Booking Number: ${this.bookingFullInfo.invoice}` || ''
            const link = `${ this.attrLink }Transactions/ordersdetailcouponOwner/${this.bookingFullInfo.id}`
            window.open(link, '_blank').focus();
            await this.pushData('onClick', 'showCouponRsv', 'showCouponRsv', this.$route.name, order ) // push a back del registro
        },
        requestBookingError( message = '' ){
            const title = this.$t("This reservation does not comply with the contract criteria")
            const text = message == '' ? this.$t("This reservation does not comply with the contract criteria...") : message
            this.$swal.fire({ icon: "error", title, text }) //muestro alerta de error
        },
        async nearDatesModal(info){
            const {icon, title, message, data} = info
            const h = this.$createElement

            // const titleVNode = h('label', { domProps: { innerHTML: title } })

            const datafields = [
                {key: 'code', label: this.$t('booking ID') },
                {key: 'datein', label:  this.$t('Check in') },
                {key: 'dateout', label: this.$t('Check out') }
            ]

            const messageVNode = h('div', { class: ['foobar'] }, [
                // h('feather-icon', { props: {icon} }),
                h('p', { class: ['text-center mb-1'] }, [
                    `${message}`,
                    h('br'),
                    h('strong', [` ${this.$t('Your dates') }: ${this.booking.checkIn} ➡️ ${this.booking.checkOut}`]),
                ]),
                h('b-table', {
                    props: {
                        items: data,
                        fields: datafields,
                        small: true,
                        responsive: true,
                    }
                })
            ])

            const modal = await this.$bvModal.msgBoxOk([messageVNode],{
                title,
                size: 'sm',
                centered: true,
                hideHeaderClose: false,
            })

            return modal
        },
        async requestCancelBooking(){
            const penalities = await this.fetchBookingsPenalities(this.bookingFullInfo.id)
            if (penalities) return this.$swal.fire({
                icon: "warning",
                title: `${this.$t("cannot cancell reservation")}`,
                text: `${this.$t("contact econcierge to cancell this booking")}`,
            })
            const dataRsv = {
                motiveCancel: null,
                cancelNote: '',
                penaltyNights: this.bookingFullInfo.nights || 0,
                extraCancelNote: '',
            }

            this.cancelRsv = dataRsv
            this.$root.$emit("bv::show::modal", "modal-cancelReserv" + this.bookingFullInfo.id)
            await this.pushData('onClick', 'openRsvCancellationRequest', 'openRsvCancellationRequest', this.$route.name ) // push a back del registro

        },
        async cancelBooking(data){
            if( this.isAdmin ){
                this.$root.$emit('bv::hide::modal', 'modal-exchanges')
                return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`})
            }
            this.isCancelingRsv = true
            const response = await this.cancelOwnerBooking(data)
            if (response) {
                this.isCancelingRsv = false
                this.$root.$emit('bv::hide::modal', 'modal-cancelReserv' + data.id)
                showAlertMessage( `${this.$t('book cancelled')}`, 'BellIcon', `${this.$t('the booking was cancelled')}`, 'success', 4000, 'bottom-right')
                this.cancelRsv = {
                    motiveCancel: null,
                    cancelNote: '',
                    penaltyNights: 0,
                    extraCancelNote: '',
                }
                await this.pushData('onClick', 'requestReservationCancellationHasBeenSent', 'requestReservationCancellationHasBeenSent', this.$route.name ) // push a back del registro
                this.$emit('get-bookings')
            } else this.isCancelingRsv = false
        },
        formDate(date){
            return formatDate({date, language: this.user?.languageName})
        },
        validateBenefitsToUse(){
            const count = this.benefitsToUseInBooking.filter(item => parseFloat(item.qty) > parseFloat(item.balance)).length               
			return {
				canUseBenefits: count === 0,
				title: this.$t("benefits"),
				text: this.$t("you have benefits in use that exceed your balance")
			}			
		},
		payloadBenefits(){
			const benefits = this.benefitsToUseInBooking			
			.map(item => {
				return {	
					id: item.id || null,
					balance: item.balance,
					benefit: item.benefit,
					createdBy: item.createdBy || 116,
					owner: item.owner || this.user.idOwner,
					authorizedBy: item.authorizedBy || this.user.idOwner,                
					status: 2,                
					ownerbooking: null,
					transaction: item.transaction,
					qty: item.qty,					
				}
			}).filter(item => item.qty > 0 || item.id !== null)
			return benefits
		}, 
        async confirmUseBenefits( questionRejectBenefits = ''){            
            const tableContent = this.benefitsToUseInBooking.filter(item => item.qty > 0).map(benefit => `
                <tr>
                    <td style="text-align: left; padding: 4px; font-size: 12px;">${ this.nameBenefit( benefit ) }</td>
                    <td style="padding: 4px; font-size: 12px;">${benefit.qty}</td>
					<td style="padding: 4px; font-size: 12px;">${benefit.year}</td>
                </tr>
                `).join('')

            const { isConfirmed } = await this.$swal.fire({
                // title: this.$t("Authorize benefits"),
                html: `
                    <div style="font-size: 14px;">
                        <b>${ questionRejectBenefits ? questionRejectBenefits: this.$t("Are you certain you wish to authorize the following benefits")}</b>	
                        <br>			
                        <table style="width: 100%; border-spacing: 0; border-collapse: collapse;" class="table table-sm mt-2">
                            <thead>
                                <tr>
                                    <th style="text-align: left;  padding: 4px; font-size: 12px;">${ this.$t("benefit name") }</th>
                                    <th style="padding: 4px; font-size: 12px;">
                                        ${ questionRejectBenefits ? this.$t("quantity to reject") : this.$t("Quantity to authorize") }
                                    </th>
									<th style="padding: 4px; font-size: 12px;">${this.$t("year")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${tableContent}  
                            </tbody>
                        </table>
                    </div>
                `,
                showDenyButton: true,               
                confirmButtonText: `${ this.$t('Yes, sure') }`,
                denyButtonText: `${ this.$t('Cancel') }`,
                customClass: {                
                    confirmButton: 'mr-1',        
                }
            })
            return isConfirmed
        },
        nameBenefit( benefit ){
            const idLangUser = this.user.languageId || 1          
            return idLangUser === 1 ? benefit.benefitnameEng : benefit.benefitname
        }
        
    },
}
</script>

<style>
@media print {
    body {
        visibility: hidden;
    }
    button, a{
        /* visibility: hidden; */
        display: none !important;
    }
    .head-logo{
        display: block !important;
        max-width: 200px;
    }
    #section-to-print {
        visibility: visible;
        /* position: absolute; */
        left: 0;
        top: 0;
    }
}
.even-more-bolder{
    font-weight: 900;
}
.head-logo{
    display: none;
    max-width: 200px;
    margin: 0;
}
.head__img {
    display: block;
    width: 100%;
}
.data-rsv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.not-found-btn{
    display: flex;
    justify-content: flex-end;
}
.title{
    font-size: x-large;
    font-weight: bold;
}
.subtitle{
    font-size: medium;
    font-weight: bold;
}
</style>